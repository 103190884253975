<template>
  <v-main app>
    <!-- Provides the application the proper gutter -->

    <!-- fill-height class add for center -->
    <!-- <v-skeleton-loader
      v-if="getThemeMode.isLoading"
      :loading="getThemeMode.isLoading"
      height="100vh"
      width="100%"
      color="red"
      type="table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article,table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article"
    ></v-skeleton-loader> -->

    <v-navigation-drawer app v-model="drawer" temporary>
      <v-img :src="logoImage" class="ma-8" alt="TicketMe Logo" />
      <v-divider />
      <v-list nav class="mt-8">
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container
      class="horizontal-container rounded-lg relative"
      :style="bgStyle"
      style="
        margin-top: 105px !important;
        background-size: 100%;
        background: transparent !important;
      "
    >
      <v-app-bar
        rounded
        :class="{
          'mb-4': !$vuetify.breakpoint.smAndDown,
          'rounded-b-0': $vuetify.breakpoint.smAndDown,
        }"
        elevation="0"
        :dark="$vuetify.breakpoint.smAndDown || undefined"
        :height="!$vuetify.breakpoint.smAndDown ? 'auto' : '75px'"
        :fixed="$vuetify.breakpoint.smAndDown"
        :style="{
          bottom: $vuetify.breakpoint.smAndDown ? '-1px' : undefined,
          top: $vuetify.breakpoint.smAndDown ? 'auto' : undefined,
        }"
        v-if="isAuthenticated && !hiddenAppBar"
      >
        <div class="d-flex align-center w-full">
          <!-- <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.smAndDown"
            @click="drawer = !!drawer ? false : true"
          /> -->
          <div
            class="d-flex align-center w-full my-2"
            :class="{
              'justify-end': !$vuetify.breakpoint.smAndDown,
              'justify-space-around pb-2': $vuetify.breakpoint.smAndDown,
              'my-2': $vuetify.breakpoint.smAndDown,
            }"
            style="gap: 0.5rem"
          >
            <v-btn
              v-for="(item, index) in items"
              :key="index"
              :to="item.path"
              text
              :large="$vuetify.breakpoint.smAndDown"
              :rounded="$vuetify.breakpoint.smAndDown"
            >
              <div
                class="d-flex align-center"
                :class="{
                  'flex-column': $vuetify.breakpoint.smAndDown,
                  'gap-1': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-icon
                  :left="!$vuetify.breakpoint.smAndDown"
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  {{ item.icon }}
                </v-icon>
                <small>{{ item.text }}</small>
              </div>
            </v-btn>
          </div>
        </div>
      </v-app-bar>
      <transition name="slide-fade" mode="out-in">
        <!-- <v-fade-transition mode="out-in" > -->
        <router-view />
        <!-- </v-fade-transition> -->
      </transition>
    </v-container>

    <div
      class="flex-grow-1"
      :class="{ 'mb-8': $vuetify.breakpoint.smAndDown }"
    ></div>
    <app-footer></app-footer>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AppFooter: () => import("./Footer"),
    TopNavbar: () => import("./TopNavbar"),
    TopNavMenu: () => import("./TopNavMenu"),
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    hiddenAppBar() {
      return this.$route.meta.hiddenAppBar || false;
    },
    logoImage() {
      return require(`@/assets/images/logo/logo-${
        this.$vuetify.theme.dark ? "light" : "dark"
      }.png`);
    },
    bgStyle() {
      if (!this.imageUrl) return null;

      const color = this.$vuetify.theme.dark ? "#1e1e1e" : "#fff";
      return {
        "background-size": "100vw",
        backgroundImage: `radial-gradient(circle at 0% 0%, transparent 0%, ${color} 90vw),
        linear-gradient(
          to bottom,
          transparent 0%,
          ${color} min(470px, calc(100vw / (16 / 9)))
                    ),
                    url("${this.imageUrl}")`,
        "background-repeat": "no-repeat",
      };
    },
  },
  data() {
    return {
      drawer: false,
      imageUrl: null,
      items: [
        {
          type: "link",
          text: "Eventos",
          path: "/shop",
          icon: "mdi-calendar",
        },
        {
          type: "link",
          text: "Filiações",
          path: "/app/membership",
          icon: "mdi-card-account-details-star",
        },
        {
          type: "link",
          text: "Ingressos",
          path: "/app/ticket",
          icon: "mdi-ticket",
        },
      ],
    };
  },

  methods: {
    setImgBg(url) {
      this.imageUrl = url;
    },
  },
  mounted() {
    this.$root.$on("setImgBg", this.setImgBg);
    this.$root.$on("setBg", this.setBg);
  },
  destroyed() {
    this.$root.$off("setImgBg", this.setImgBg);
    this.$root.$off("setBg", this.setBg);
  },
};
</script>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.horizontal-container {
  z-index: 1;
}
</style>
